import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../metaContentValues';

const interestOnlyTermExtension: AppContent['interestOnlyTermExtension'] = {
  metaContent: MetaContentValues.interestOnlyTermExtension,
  pageTitle: 'Interest Only Term Extension',
  termExtensionSection: {
    termExtensionText1:
      "You can apply for a term extension on an interest only mortgage by selecting the button below. You'll then be taken to an online form, which you'll need to complete.",
    termExtensionText2:
      "The form is hosted on the Bank of Scotland website, which is owned by Lloyds Banking Group like Birmingham Midshires. We'll know it's your Birmingham Midshires mortgage as the form has been built just for these accounts.",
    termExtensionButtonUrl:
      'https://www.bankofscotland.co.uk/mortgages/existing-customers/bm-mortgages/io-term-extension.html',
    termExtensionButtonLabel: 'Apply for term extension',
  },
};

export default interestOnlyTermExtension;
