import React from 'react';

import {
  Link,
  Button,
  ContentGroup,
  Heading,
  Hr,
  Main,
  Paragraph,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { Helmet } from 'react-helmet';

import { InterestOnlyTermExtensionContent } from './InterestOnlyTermExtension.config';

function InterestOnlyTermExtensionPage(): React.JSX.Element {
  const { metaContent, pageTitle, termExtensionSection } =
    useContent<InterestOnlyTermExtensionContent>();

  return (
    <Main>
      <Helmet>
        <title>{metaContent.title}</title>
        <meta name="description" content={metaContent.description} />
      </Helmet>
      <ContentGroup marginTop="05">
        <Heading as="h1" size="s7" marginTop="05">
          {pageTitle}
        </Heading>
        <Hr />
        <Paragraph>{termExtensionSection.termExtensionText1}</Paragraph>
        <Paragraph>{termExtensionSection.termExtensionText2}</Paragraph>
        <Link
          as={Button}
          external
          href={termExtensionSection.termExtensionButtonUrl}
        >
          {termExtensionSection.termExtensionButtonLabel}
        </Link>
      </ContentGroup>
    </Main>
  );
}

export default InterestOnlyTermExtensionPage;
