import React, { ReactElement, useEffect, useRef } from 'react';

import { ContentProvider } from '@interstellar/react-app-content';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from '@interstellar/react-app-routing';
import { logTealiumPageViewEvent } from 'client/tealium/functions/logTealiumPageViewEvent';

import { AccessibilityPage } from './accessibility';
import { BankOfEnglandBaseRatePage } from './bankOfEnglandBaseRate';
import { MortgageCalculatorsPage } from './calculators';
import { MortgagePaymentCalculatorPage } from './calculators/mortgagePaymentCalculator';
import { RateChangeCalculatorPage } from './calculators/rateChangeCalculator';
import { ComplaintsPage } from './complaints';
import { ContactUsPage } from './contactUs';
import { CookiesPage } from './cookies';
import { EligibilityPage } from './eligibility';
import { FeesAndChargesPage } from './feesAndCharges';
import { Homepage } from './homepage';
import { HowToMakeAPaymentPage } from './howToMakeAPayment';
import IncomeAndExpenditurePage from './incomeAndExpenditure/IncomeAndExpenditurePage';
import InterestAndProductsPage from './interestAndProducts/components/InterestAndProductsPage';
import { InterestOnlyTermExtensionPage } from './interestOnlyTermExtension';
import KnowledgeBasePage from './knowledgeBase/KnowledgeBasePage';
import { LandlordSupportPage } from './landlordSupport';
import { MakingOverpaymentsPage } from './makingOverpayments';
import { ManageYourMortgagePage } from './manageYourMortgage';
import * as routes from './manifest';
import NotFoundPage from './notFound/NotFoundPage';
import { OurMortgagesPage } from './ourMortgages';
import { PaymentsPage } from './payments';
import { PortfolioLandlordPage } from './portfolioLandlord';
import { PrivacyPage } from './privacy';
import { PropertyDeedsPage } from './propertyDeeds';
import QuestionsAboutLendingPage from './questionsAboutLending/components/QuestionsAboutLendingPage';
import { InternalRedirectDefinitions } from './redirectDefinitions';
import { SiteMapPage } from './siteMap';
import { SustainabilityPage } from './sustainability';
import { TermsAndConditionsPage } from './termsAndConditions';
import { WorriedAboutFuturePaymentsPage } from './worriedAboutFuturePayments';
import useAppConfig from '../components/appConfig/useAppConfig';
import content from '../content';
import { getThemeFromUrl } from '../utils/getThemeFromUrl';

function App(): ReactElement {
  const brandConfig = useAppConfig().BRAND_NAME;
  const brand = getThemeFromUrl() || brandConfig;

  const existingRoutes = Object.values(routes);
  const { pathname, state } = useLocation();

  useEffect(() => {
    const trackPageView = () => {
      // If we're the homepage log and return early to avoid pathname changes later on.
      if (pathname === '/') {
        logTealiumPageViewEvent({
          stepNumber: undefined,
          stepName: 'home page',
        });
        return;
      }

      const pathnameWithoutTrailingSlash = pathname.replace(/\/$/, '');

      // Skip logging if we aren't a known path. This will also skip redirects.
      if (
        !existingRoutes.find((route) => route === pathnameWithoutTrailingSlash)
      ) {
        return;
      }

      // Grab the last part of the pathname and replace dashes with spaces
      // e.g. '/existing-customers/fees-and-charges' becomes 'fees and charges'
      let stepName = pathnameWithoutTrailingSlash
        .split('/')
        .pop()
        .replace(/-/g, ' ');

      // If we have come from a redirect append the referrer
      if (state?.from) {
        stepName += ` - Referrer: ${state.from}`;
      }

      // Finally log the event
      logTealiumPageViewEvent({
        stepNumber: undefined,
        stepName,
      });
    };

    trackPageView();
    // previousPathnameRef.current = fullPath;
  }, [pathname, state, existingRoutes]);

  return (
    <Routes>
      <Route
        path={routes.Home}
        element={
          <ContentProvider value={content({ brand }).home}>
            <Homepage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.MortgageCalculators}
        element={
          <ContentProvider value={content({ brand }).mortgageCalculators}>
            <MortgageCalculatorsPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.Payments}
        element={
          <ContentProvider value={content({ brand }).payments}>
            <PaymentsPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.KnowledgeBase}
        element={
          <ContentProvider value={content({ brand }).knowledgeBase}>
            <KnowledgeBasePage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.HowToMakeAPayment}
        element={
          <ContentProvider value={content({ brand }).howToMakeAPayment}>
            <HowToMakeAPaymentPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.WorriedAboutFuturePayments}
        element={
          <ContentProvider
            value={content({ brand }).worriedAboutFuturePayments}
          >
            <WorriedAboutFuturePaymentsPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.MakingOverpayments}
        element={
          <ContentProvider value={content({ brand }).makingOverpayments}>
            <MakingOverpaymentsPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.QuestionsAboutLending}
        element={
          <ContentProvider value={content({ brand }).questionsAboutLending}>
            <QuestionsAboutLendingPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.Sustainability}
        element={
          <ContentProvider value={content({ brand }).sustainability}>
            <SustainabilityPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.InterestAndProducts}
        element={
          <ContentProvider value={content({ brand }).interestAndProducts}>
            <InterestAndProductsPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.ContactUs}
        element={
          <ContentProvider value={content({ brand }).contactUs}>
            <ContactUsPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.Eligibility}
        element={
          <ContentProvider value={content({ brand }).eligibility}>
            <EligibilityPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.OurMortgages}
        element={
          <ContentProvider value={content({ brand }).ourMortgages}>
            <OurMortgagesPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.ManageYourMortgage}
        element={
          <ContentProvider value={content({ brand }).manageYourMortgage}>
            <ManageYourMortgagePage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.PortfolioLandlord}
        element={
          <ContentProvider value={content({ brand }).portfolioLandlord}>
            <PortfolioLandlordPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.BankOfEnglandBaseRate}
        element={
          <ContentProvider value={content({ brand }).bankOfEnglandBaseRate}>
            <BankOfEnglandBaseRatePage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.FeesAndCharges}
        element={
          <ContentProvider value={content({ brand }).feesAndCharges}>
            <FeesAndChargesPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.Complaints}
        element={
          <ContentProvider value={content({ brand }).complaints}>
            <ComplaintsPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.PropertyDeeds}
        element={
          <ContentProvider value={content({ brand }).propertyDeeds}>
            <PropertyDeedsPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.RateChangeCalculator}
        element={
          <ContentProvider value={content({ brand }).rateChangeCalculator}>
            <RateChangeCalculatorPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.CookiesPolicy}
        element={
          <ContentProvider value={content({ brand }).cookies}>
            <CookiesPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.TermsAndConditions}
        element={
          <ContentProvider value={content({ brand }).termsAndConditions}>
            <TermsAndConditionsPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.Accessibility}
        element={
          <ContentProvider value={content({ brand }).accessibility}>
            <AccessibilityPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.PrivacyPolicy}
        element={
          <ContentProvider value={content({ brand }).privacy}>
            <PrivacyPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.MortgagePaymentCalculator}
        element={
          <ContentProvider value={content({ brand }).mortgagePaymentCalculator}>
            <MortgagePaymentCalculatorPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.SiteMap}
        element={
          <ContentProvider value={content({ brand }).siteMap}>
            <SiteMapPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.IncomeAndExpenditure}
        element={
          <ContentProvider value={content({ brand }).incomeAndExpenditure}>
            <IncomeAndExpenditurePage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.LandlordSupport}
        element={
          <ContentProvider value={content({ brand }).landlordSupport}>
            <LandlordSupportPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.InterestOnlyTermExtension}
        element={
          <ContentProvider value={content({ brand }).interestOnlyTermExtension}>
            <InterestOnlyTermExtensionPage />
          </ContentProvider>
        }
      />
      {
        // internal redirects
        Object.entries(InternalRedirectDefinitions).map(
          ([oldPath, newPath]) => (
            <Route
              key={oldPath}
              path={oldPath}
              element={<Navigate to={newPath} state={{ from: oldPath }} />}
            />
          ),
        )
      }
      {
        // internal redirects with trailing slash
        Object.entries(InternalRedirectDefinitions).map(
          ([oldPath, newPath]) => (
            <Route
              key={`${oldPath}/`}
              path={`${oldPath}/`}
              element={<Navigate to={newPath} state={{ from: oldPath }} />}
            />
          ),
        )
      }
      <Route
        path="*"
        element={
          <ContentProvider value={content({ brand }).notfound}>
            <NotFoundPage />
          </ContentProvider>
        }
      />
    </Routes>
  );
}

export default App;
