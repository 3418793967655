import React from 'react';

import { Heading, ListItem, Main, Paragraph } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import MetaTags from 'client/components/metaTags/MetaTags';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import { getMetaTagContent } from 'client/handlers/getMetaTagContent';

import { SiteMapContent } from './SiteMapPage.config';
import { StyledList } from './SiteMapPage.styled';
import * as routes from '../../manifest';

export const getLinkTitleFromMetaTitle = (route: string) => {
  const onlyPageTitle = route.split('|')[0];
  return onlyPageTitle.trim();
};

const manifestFilePaths = Object.values(routes).filter(
  (path) => path !== routes.InterestOnlyTermExtension,
);

function SitemapPage(): React.JSX.Element {
  const { metaContent, pageTitle } = useContent<SiteMapContent>();

  const getLeftPadForPath = (path: string) => {
    const LEADING_SLASH_REGEX = /^\/+/;
    const TRAILING_SLASH_REGEX = /\/+$/;
    const SLASH_REGEX = /\//g;

    // remove leading / trailing slashes
    const pathNoLeadingOrTrailingSlash = path
      .replace(LEADING_SLASH_REGEX, '')
      .replace(TRAILING_SLASH_REGEX, '');

    // count nesting level using number of slashes in the path
    const nestLevel =
      pathNoLeadingOrTrailingSlash.match(SLASH_REGEX)?.length ?? 0;
    const nestMultiplier = 6;

    const numberOfSpaces = nestLevel * nestMultiplier;

    return (
      <>
        {Array.from({ length: numberOfSpaces }, (_, i) => (
          <span key={i}>&nbsp;</span>
        ))}
      </>
    );
  };
  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <Heading
        data-testid={`${pageTitle}-heading`}
        as="h1"
        size="s7"
        marginTop="05"
      >
        {pageTitle}
      </Heading>

      <StyledList>
        {manifestFilePaths.sort().map((path) => {
          const metaTitle = getMetaTagContent(path).title;
          const currentLinkTitle = getLinkTitleFromMetaTitle(metaTitle);
          const leftPadSpaces = getLeftPadForPath(path);

          return (
            <ListItem key={path} icon={false}>
              <Paragraph marginBottom="none" marginTop="none">
                {leftPadSpaces}
                <StyledLink data-testid={`sitemap-route-${path}`} to={path}>
                  {currentLinkTitle}
                </StyledLink>
              </Paragraph>
            </ListItem>
          );
        })}
      </StyledList>
    </Main>
  );
}

export default SitemapPage;
